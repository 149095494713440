import React from 'react'
import { graphql } from 'gatsby'
import { Block, Breadcrumbs, Layout, Hero, Container } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '@pageBuilder/wrapper'

const Portfolio = ({ data, pageContext }) => {
  const { moduleArea, title, path, seoMetaTags, image, imageMobile } = data.post
  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      <Hero image={image} mobileImage={imageMobile} />
      {pageContext?.breadcrumbs && (
        <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
      )}
      <Block paddingTop={'single'}>
        <Container center>
          <h1>{title}</h1>
        </Container>
      </Block>
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query portfolioById($id: String!) {
    post: datoCmsPortfolio(id: { eq: $id }) {
      id
      slug
      path
      title
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "550", fit: "crop", w: "1600", q: 60 }
        )
        alt
      }
      imageMobile: image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "600", fit: "crop", w: "750", q: 60 }
        )
        alt
      }
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsContentCard {
          ...ContentCard
        }
        ... on DatoCmsCta {
          ...Cta
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageGrid {
          ...ImageGrid
        }
        ... on DatoCmsPeopleGrid {
          ...PeopleGrid
        }
        ... on DatoCmsVideo {
          ...Video
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Portfolio
